import logo from "../images/logo.png";
import {
    annualCalendarList,
    statisticYears,
} from "../data/dropdownValues";
import Dropdown from "./UI/Dropdown";
import { IOption } from "../types";
import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderSlider from "./HeaderSlider/HeaderSlider";
import { DateContext } from "../contexts/DateContext";
import Notifications from "./Notifications/Notifications";
import { localTimezone } from "./UI/DatePicker";
import HeaderAsideMenu from "./HeaderAsideMenu";

const Header = () => {
    const location = useLocation();
    const { setSelectedDate, setSelectedYear, selectedYear, calendarPeriod, setCalendarPeriod } =
        useContext(DateContext);
    const [isTablet, setIsTablet] = useState<boolean>(false);

    useEffect(() => {
        handleResize();
        if (visualViewport) {
            visualViewport.addEventListener("resize", () => {
                handleResize();
            });
        }
        return () => {
            if (visualViewport) {
                visualViewport.removeEventListener("resize", handleResize);
            }
        };
    }, []);

    const handleResize = () => {
        setIsTablet(window.innerWidth <= 925);
    };

    const handleSelect = (option: IOption) => {
        setCalendarPeriod(option);
    };

    const handleSelectYear = (option: IOption) => {
        setSelectedYear(option.value.toString());
    };

    const handleClickToday = () => {
        setSelectedDate(new Date());
        sessionStorage.setItem("selectedDay", localTimezone(new Date()));
    };

    return (
        <div className="header" id="header">
            <div className="header-item">
                <HeaderAsideMenu />
                <img src={logo} alt="logo" />
                <span className="header-item-name">Медиапланирование ВКО</span>
            </div>

            <div className="header-item header-right">
                {location.pathname === "/" && !isTablet ? (
                    <div className="header-center">
                        <HeaderSlider calendarPeriod={calendarPeriod} />
                    </div>
                ) : null}
                {location.pathname === "/" ? (
                    <div
                        className="header-today"
                        onClick={handleClickToday}
                    >
                        <div className="header-today">Сегодня</div>
                    </div>
                ) : null}
                {location.pathname === "/" && (
                    <Dropdown
                        options={annualCalendarList}
                        onSelect={handleSelect}
                        selectedOpt={calendarPeriod}
                    />
                )}
                {location.pathname === "/statistics" && (
                    <Dropdown
                        options={statisticYears}
                        onSelect={handleSelectYear}
                        selectedOpt={{
                            label: selectedYear.toString(),
                            value: selectedYear,
                        }}
                    />
                )}
                <div className="notification">
                    <Notifications />
                </div>
            </div>
        </div>
    );
};

export default Header;
